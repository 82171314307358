import React, { useContext } from "react";
import { graphql } from "gatsby";

import { LangContext } from "../context/LangContext";
import { markdownByLang } from "../utils/markdown";
import { PageContainer, PageArticle } from "../styled/Layout.styled";
import Layout from "../components/Layout";

const About = ({ data }) => {
  const { lang } = useContext(LangContext);
  const content = markdownByLang(data);

  return (
    <Layout>
      <PageContainer>
        <h1>{content[lang].heading}</h1>
        <h2>{content[lang].description}</h2>
        <PageArticle
          dangerouslySetInnerHTML={{ __html: content[lang].html }}
        ></PageArticle>
      </PageContainer>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query AboutPageContent {
    page: allMarkdownRemark(
      filter: { frontmatter: { title: { regex: "/about-page/" } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          heading
          description
        }
      }
    }
  }
`;
